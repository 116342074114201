.dropdown-selection-radio-filters {
  .section-title {
    padding-bottom: 6px;
  }

  .ant-tag {
    margin-bottom: 4px;
    cursor: pointer;
  }

  .filter-radio-item {
    text-transform: capitalize;
  }
}