@import '../../constants/style-constants';

.select-image-wrapper {
  padding: 0;
  height: 100%;
}

.header-section {
  padding-bottom: 20px;
}

.header-filters {
  display: flex;
  justify-content: flex-end;
}

.image-list-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  margin: 20px 0 30px;
  padding: 10px 0;
  max-height: calc(80vh - 190px);
  overflow-y: auto;
}

.image-item-card {
  width: 33%;
  min-width: 80px;
  max-width: 320px;
  align-items: center;
  text-align: center;
  margin: 0 0 20px;
  cursor: default;

  .ant-card-head {
    padding: 0 15px;

    .ant-card-head-title {
      font-size: 14px;
    }
  }

  .ant-card-cover {
    cursor: pointer;
  }

  .ant-card-body {
    height: 40px;
    padding: 0 !important;
    cursor: default;
  }
}

.image-item {
  min-width: 80px;
  max-width: 170px;
  max-height: 150px;
  min-height: 100px;
  background: $card-background;
}

.select-image-item-button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}
