@import '../../constants/style-constants';

.accessible-table {
  width: 100% !important;
}

.highlighted-row {
  background-color: #fafafa !important;

  .ant-table-cell-fix-left, .ant-table-cell-fix-right {
    background-color: #fafafa !important;
  }
}

.sticky-table-title {
  .ant-table-title {
    position: sticky;
    z-index: 5;
    top: 0;
  }
}

.accessible-table-action {
  padding: 0 5px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.accessible-table-image-cell {
  display: flex;
  justify-content: center;
}

.accessible-table-image-cell-mask {
  background: hsla(0, 0%, 0%, .5);
  min-width: 70px;
  color: #fff;
}

.column-filters-selection-wrap {
  min-width: 150px;
  max-height: 400px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;

  .column-filters-selection-group {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;

    .ant-checkbox-wrapper {
      &.ant-checkbox-wrapper {
        margin: 5px 0;
      }
    }

    .text-icon {
      margin-right: 5px;
    }
  }

  .apply-filter-actions {
    margin: 15px 0 0;
    display: flex;
    flex-direction: row-reverse;
  }
}

.accessible-table-text-area {
  &.ant-input-group {
    &.ant-input-group-compact {
      display: flex;
      flex-direction: row;

      .accessible-table-text-area-button {
        margin-left: 5px;
      }
    }
  }
}

.errored-input-text-area {
  border-color: red;

  &:focus {
    border-color: red;
  }
}

.accessible-table-preview-cell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .watch-icon {
    width: 30px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.accessible-table-preview-cell-modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accessible-table-tags-cell {
  margin: 2px 5px;

  &.borderless {
    border: none;
  }

  &.colorless {
    background: inherit;
  }
}

.accessible-table-input-number-edit-cell {
  display: flex;
  flex-direction: row;
}

.accessible-table-text-cell {
  margin-bottom: 0 !important;
}
