.workout-video-editor-sidebar {
  height: 100%;
  overflow: hidden;

  .movement-list-wrap {
    height: calc(100% - 60px);
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    margin: 20px 0 10px 10px;

    .movement-list-item-selected {
      color: white;
      background: #1890ff;
    }
  }
}

