$base-layout-padding: 40px;
$main-color: var(--primary-background, hsla(209, 100%, 55%, 1));
$primary-background: var(--primary-background, hsla(0, 100%, 100%, 1));
$secondary-background: var(--primary-background, hsla(0, 0%, 0%, 1));

$primary-header-background: var(--primary-background, hsla(200, 42%, 63%, 1));
$divider-color: var(--primary-background, hsla(0, 0%, 0%, 0.06));
$card-highlighted: var(--primary-background, hsla(209, 100%, 55%, .5));
$card-background: var(--primary-background, hsla(200, 1%, 63%, 1));

$success-color: var(--primary-background, rgb(82, 196, 26));
$error-color: var(--primary-background, rgb(255, 77, 79));

:export {
  mainColor: $main-color;
  primaryBackground: $primary-background;
  primaryHeaderBackground: $primary-header-background;
  dividerColor: $divider-color;
  cardHighlighted: $card-highlighted;
  cardBackground: $card-background;
  successColor: $success-color;
  errorColor: $error-color;
  baseLayoutPadding: $base-layout-padding;
}
