.workout-record-status-selection {
  &.ant-form-item,
  &.ant-select {
    width: 150px;
  }
}

.workout-trainer-filter-dropdown {
  width: 700px;
  padding: 20px;
}
