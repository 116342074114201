.upload-images-modal-wrap {
  .ant-modal-body {
    padding: 0;
  }
}

.upload-images-wrapper {
  height: 100%;

  .upload-images-content-wrapper {
    position: relative;
  }

  .upload-images-content {
    padding: 0 25px;
  }

  .upload-images-loader {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .upload-images-list {
    height: 700px;
    max-height: 80vh;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 20px;

    .ant-list-item {
      display: flex;
      flex-direction: row-reverse;
      padding: 4px;
      margin: 4px 0;

      &.ant-list-item-error {
        border: 1px solid red;
      }

      .ant-list-item-extra {
        margin: 0;
      }

      .ant-list-item-main {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;

        .ant-list-item-meta {
          margin-bottom: 0;
          justify-content: center;
          padding-right: 5px;
        }

        .ant-list-item-action {
          margin-top: 0;
          margin-left: 0;
           li {
             padding: 0;
           }
        }
      }
    }
  }

  .upload-images-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 2;
    padding: 0 25px 25px;

    .upload-images-input {
      display: none;
    }

    .upload-images-button {
      justify-self: start;
      padding: 0 20px;
      height: 40px;
      color: #fff;
      cursor: pointer;
      background: rebeccapurple;
      box-shadow: 0 5px 3px 2px rgba(102, 51, 153, 0.5);
      transition: all 0.5s ease 0s;
      border-radius: 5px;

      &:hover {
        box-shadow: none;
        transform: translateY(5px);
      }
    }
  }

}