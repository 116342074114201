.time-input {
  width: 60px;
}

.ant-picker {
  &.error {
    border-color: red;
  }
}

.ant-picker-dropdown {
  .ant-picker-footer-extra {
    line-height: 18px;
    padding: 12px;
    border-top: 1px solid red;
    border-bottom: 1px solid red;

    .error-message {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .icon {
        color: red;
        padding-right: 12px;
        font-size: 16px;
      }
    }
  }
  &.error {
    .ant-picker-ok {
      display: none;
    }
  }
}
