.page-container {
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.actions {
  display: flex;
  padding: 30px 20px 20px;
  justify-content: flex-end;
}
