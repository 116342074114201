.select-radio-group-dropdown {
  display: flex;
  flex-direction: column;

  .ant-dropdown-menu-group {
    display: flex;
    flex-direction: column;
  }
}

.select-radio-group-dropdown-button {
  min-width: 170px;
}