.dropdown-record-selection-list-wrap {
  padding: 5px;
}

.dropdown-record-selection-list-overlay {
  width: 90vh;
  max-width: 850px;
}

.dropdown-record-selection-list-section-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .dropdown-record-selection-list-section {
    width: 50%;
  }
}

.dropdown-record-selection-list-section {
  padding: 10px;

  .ant-typography {
    text-transform: uppercase;
  }
}

.dropdown-record-selection-list {
  height: 500px;
  max-height: calc(90vh - 200px);
  width: 100%;
  overflow: hidden;
  overflow-y: auto;

  .dropdown-record-selection-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    margin: 3px 0;
    cursor: pointer;

    .item-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.selected {
      background: #E6F7FF;
      box-sizing: border-box;
      border-radius: 2px;
    }
  }

  .ant-list-item:last-child {
    border-bottom: 1px solid #d9d9d9;
  }
}

.movement-record-selection-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
