.resource-record-page-wrapper {
  padding-left: 50px;
  padding-right: 50px;
}

.workout-record-wrapper {
  .ant-form-item-label {
    min-width: 205px;
  }
}

.record-form {
  &.ant-form {
    .ant-form-item-label {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }
}

.ant-space-full-width {
  width: 100%;

  .ant-space-item {
    width: 100%;
  }
}

.card-content-wrap {
  position: relative;
  margin: 0;
  padding: 15px 10px;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}
