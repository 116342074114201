.col-checkbox-selection-item {
  overflow: hidden;

  .ant-checkbox-wrapper {
    max-width: 100%;
    width: 100%;

    > span:last-child {
      width: 100%;
      max-width: 100%;
    }
  }

  .checkbox-selection-item {
    max-width: calc(100% - 10px) !important;;
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.highlighted {
      font-weight: 800;
    }
  }
}

