.form-item-image-card {
  min-width: 150px;
  max-width: 300px;
  align-items: center;
  text-align: center;
  margin: 10px 0;

  .ant-card-body {
    display: none;
  }
}

.form-item-image {
  min-width: 150px;
  max-width: 300px;
  max-height: 300px;
}

.form-item-record-selection-delete-btn {
  display: flex;
  justify-content: center;
  cursor: pointer;

  .anticon {
    font-size: 22px;
    color: rgba(0, 0, 0, 0.6)
  }
}

.form-item-cascader-selection-dropdown {
  .ant-cascader-menu {
    height: 250px;
  }
}

.form-item-selection {
  &.disabled {
    opacity: .5;
  }
}
