.workout-movements-page {
  overflow: hidden;

  .workout-movements-table {
    overflow-y: auto;

    .workout-movements-table-space {
      display: block;
      margin: 20px 0;
    }
  }
}

.movements-page-footer {
  padding-top: 10px;

  .return-button {
    display: flex;
    justify-content: flex-end;
    max-width: 150px;
  }
}

.movements-page-subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}

.movements-header-actions {
  display: flex;
  justify-content: flex-end;
}

.ant-steps-item-description {
  position: relative;

  .create-movement-block-form-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: rgba(240, 240, 240, .5);
  }
}

.create-movement-block-form {
  position: relative;
  padding: 20px;
}

.movements-transfer-wrap {
  width: 100%;

  .ant-transfer-list {
    width: 310px;
    height: 400px;
  }
}

.add-movements-to-box-table {
  border-top: 1px solid rgba(128, 128, 128, .1);
  padding-bottom: 30px;
}

.workout-movement-table-context-menu {
  display: flex;
  flex-direction: column;
  z-index: 11;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  li {
    clear: both;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    margin: 0;
    padding: 10px 15px;
    transition: all .3s;
    white-space: nowrap;
    -webkit-transition: all .3s;

    &:hover {
      background-color: #e6f7ff;
    }

    i {
      margin-right: 8px;
    }

    .label {
      padding-left: 10px;

    }

    .danger {
      color: red
    }
  }
}

.movements-selection-filters {
  width: 400px;
  padding: 16px;

  .ant-divider {
    margin: 12px 0;
  }

  .movements-selection-filters-title {
    padding-bottom: 0;
  }

  .clear-button {
    display: flex;
    justify-content: flex-end;
    color: #0A84FF;
    cursor: pointer;
  }
}

.movement-exercise-title {
  cursor: default;

  &.clickable {
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
  }

  .hidden-tag {
    opacity: 0;
  }
}
