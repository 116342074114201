.underline-divided-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: right;
}

.divider-line {
  background: #000;
  margin-top: 10px;
}