.promotion-tile-type-records-list {
  height: 500px;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 10px;

  .ant-list-item {
    padding-left: 10px;
      &:hover {
        background: rgba(24, 144, 255, 0.1);
      }
  }
}

.promotion-tile-modal-button {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 0, 0, 0.25);
}
