@import '../../constants/style-constants';

.select-video-wrapper {
  padding: 0 25px;
  height: 100%;
}

.header-section {
  padding-bottom: 20px;
}

.header-filters {
  display: flex;
  justify-content: flex-end;
}

.video-list-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  margin: 20px 0 30px;
  padding: 10px 0;
  max-height: calc(80vh - 190px);
  overflow-y: auto;
}

.select-video-item {
  margin: 10px;
  max-width: 400px;

  .select-video-item-player {
    max-height: 250px;
    max-width: 100%;
    overflow: hidden;
  }

  .select-video-item-title {
    padding-top: 10px;
  }

  .select-video-item-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
  }
}