.resource-list-page {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.resource-list-header {
  padding-bottom: 20px;
}

.action-container {
  display: flex;
  justify-content: flex-end;

  &:not(:last-child) {
    padding-right: 10px;
  }
}

.dynamic-columns-menu {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0 3px 6px -4px, rgba(0, 0, 0, 0.08) 0 6px 16px 0px, rgba(0, 0, 0, 0.05) 0 9px 28px 8px;
}
