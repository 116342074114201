body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.root {
  height: 100%;
}

.ant-image-preview-operations-operation {
  .anticon-rotate-right,
  .anticon-rotate-left {
    display: none;
  }
}

.page-title {
  justify-content: center;
  display: flex;
  padding: 0.5em 0;
}

.centred {
  width: 100%;
  height: 100%;
  justify-content: center;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.ant-notification-notice-btn {
  float: left;
  margin-top: 16px;
  margin-left: 32px;
}
