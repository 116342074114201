.content-header {
  display: flex;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  .content-header-left-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.trigger {
  margin-left: 16px;
  cursor: pointer;
}

.breadcrumbs {
  margin: 16px;
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.layout-content {
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.side-menu-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.65);
  padding: 0 34px 24px 24px;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
  width: 250px;
  overflow: hidden;
  font-size: 10px;

  .side-menu-footer-title {
    display: block;
    padding-bottom: 3px;
  }
}
